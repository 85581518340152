.app-main.id {
  background-image: url("./images/id/bg.png");
  background-repeat: no-repeat;
  background-position: center center;

  .header-nav-wrapper {
    display: none;
  }

  .app-footer {
    display: none;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #F1ECFC;
    box-shadow: 0px 2px 20px rgba(237, 98, 119, 0.2);
    border-radius: 11.6993px;
    display: inline-block;
    height: 70px;
    padding: 20px;
    font-size: 25px;
    line-height: 27px;
    color: #666666;
    width: 500px;
  }

  .id-btn {
    display: inline-block;
    width: 210px;
    height: 70px;
    margin-left: 20px;
    position: relative;
  }

  .id-btn span {
    display: block;
    position: absolute;
    background-image: url("./images/id/btn.png");
    background-size: 100% 100%;
    width: 522px;
    height: 241px;
    z-index: 1;
    background-position: center;
    transform: scale(0.5);
    top: -83px;
    left: -139px;
  }

  .id-mail {
    position: relative;
  }

  .id-mail:before {
    content: ' ';
    display: block;
    position: absolute;
    background-image: url("./images/id/evil.png");
    height: 239px;
    width: 173px;
    left: -200px;
    top: -295px;
  }

  .id-mail:after {
    content: ' ';
    display: block;
    position: absolute;
    background-image: url("./images/id/person.png");
    height: 449px;
    width: 194px;
    left: -200px;
    top: -50px
  }
}
